<template>
  <v-card>
    <v-card-title class="subheading"> Personalstärke </v-card-title>
    <v-card-subtitle v-if="leavesAvailabiliy"
      >Basierend auf eingegangenen Meldungen</v-card-subtitle
    >
    <v-card-text>
      <v-row dense>
        <v-col cols="sm">
          <v-card
            class="pa-2 text-h5 font-weight-bold green--text text--darken-4"
            dark
            outlined
            color="green lighten-4"
          >
            <div class="text-center">
              {{ organization.count.totalAvailable }}
            </div>
            <div class="font-weight-medium subtitle-2 text-center">
              Verfügbar
            </div>
          </v-card> </v-col
        ><v-col v-if="organization.count.totalInEvent > 0" cols="sm">
          <v-card
            class="pa-2 text-h5 font-weight-bold blue--text text--darken-4"
            dark
            outlined
            color="blue lighten-4"
          >
            <div class="text-center">
              {{ organization.count.totalInEvent }}
            </div>
            <div class="font-weight-medium subtitle-2 text-center">
              Bereits gebunden
            </div>
          </v-card> </v-col
        ><v-col cols="sm">
          <v-card
            class="pa-2 text-h5 font-weight-bold red--text text--darken-4"
            dark
            outlined
            color="red lighten-4"
          >
            <div class="text-center">
              {{ organization.count.totalNotAvailable }}
            </div>
            <div class="font-weight-medium subtitle-2 text-center">
              Nicht verfügbar
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title v-if="unitsAvailability && units.length > 0"
      >Einsatzmittel</v-card-title
    >
    <v-card-subtitle v-if="unitsAvailability && units.length > 0"
      >Basierend auf Fahrzeugstatus und verfügbaren Funktionen</v-card-subtitle
    >
    <v-card-text v-if="unitsAvailability && units.length > 0">
      <v-row dense>
        <v-col v-for="unit in units" :key="unit.id" cols="4">
          <v-card outlined>
            <v-img
              :src="unit.photoUrl"
              contain
              height="80px"
              class="mx-4"
            ></v-img>
            <div
              :class="[
                'align-end subtitle-2 font-weight-medium text-center text--darken-4',
                statusTextColor(unit.status),
                statusColor(unit.status),
              ]"
            >
              <span class="caption">{{ unit.callsign }}</span>
              <br />
              {{ statusText(unit.status) }}
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title v-if="positionsAvailability && positions.length > 0"
      >Funktionen</v-card-title
    >
    <v-card-subtitle v-if="positionsAvailability && positions.length > 0"
      >Basierend auf verfügbaren Qualifikationen</v-card-subtitle
    >
    <v-card-text v-if="positionsAvailability && positions.length > 0">
      <v-row dense>
        <v-col v-for="position in positions" :key="position.id" cols="3">
          <v-card
            :class="[
              'pa-2 text-h5 font-weight-bold text--darken-4 green--text',
              statusTextColor(position.status),
            ]"
            dark
            outlined
            color="green lighten-4"
          >
            <div class="text-center">
              {{ position.count }}
            </div>
            <div class="font-weight-medium subtitle-2 text-center">
              {{ position.callsign }}
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "availability-status-overview-card",
  props: {},
  data() {
    return {
      units: [
        {
          callsign: "LHF XX01/3",
          photoUrl: "",
          status: "operational",
        },
      ],
      positions: [
        {
          callsign: "FzF",
          count: "4",
        },
        {
          callsign: "Ma (+C)",
          count: "4",
        },
        {
          callsign: "TF",
          count: "4",
        },
        {
          callsign: "TL",
          count: "4",
        },
      ],
    };
  },
  computed: {
    organization() {
      return this.$store.state.organization.activeOrganization;
    },
    leavesAvailabiliy() {
      return this.$store.getters["organization/checkExtensionAvailabilityById"](
        "availability.leaves"
      );
    },
    unitsAvailability() {
      // return this.$store.getters["organization/checkExtensionAvailabilityById"](
      //   "availability.units"
      // );
      return false;
    },
    positionsAvailability() {
      // return this.$store.getters["organization/checkExtensionAvailabilityById"](
      //   "availability.units"
      // );
      return false;
    },
  },
  methods: {
    statusText(status) {
      switch (status) {
        case "operational":
          return "Einsatzbereit";
        case "not fully operational":
          return "Bedingt einsatzbereit";
        case "not operational":
          return "Nicht einsatzbereit";
        case "in operation":
          return "Im Einsatz";
        default:
          "";
      }
    },
    statusColor(status) {
      switch (status) {
        case "operational":
          return "green lighten-4";
        case "not fully operational":
          return "orange lighten-4";
        case "not operational":
          return "red lighten-4";
        case "in operation":
          return "blue lighten-4";
        default:
          "";
      }
    },
    statusTextColor(status) {
      switch (status) {
        case "operational":
          return "green--text";
        case "not fully operational":
          return "orange--text";
        case "not operational":
          return "red--text";
        case "in operation":
          return "blue--text";
        default:
          "";
      }
    },
  },
};
</script>
