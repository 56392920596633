<template>
  <div>
    <NavigationBar
      titel="Vorausplanung"
      isPreview
      :tabs="tabs"
      :activeTab="activeTab"
    ></NavigationBar>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "availability-forecasts",
  components: {
    NavigationBar,
  },
  data() {
    return {
      activeTab: `forecasts/overview`,
      tabs: [
        { id: 1, name: "Übersicht", route: `overview` },
        { id: 2, name: "Planner", route: `planner` },
      ],
    };
  },
};
</script>
