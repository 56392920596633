<template>
  <div>
    <section>
      <template>
        <v-container>
          <v-row> Matrix </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
export default {
  name: "availability-forecasts-tab-planner",
  components: {
    // Subheader,
  },
  data() {
    return {};
  },
  computed: {
    //   organization() {
    //     return this.$store.state.organization.activeOrganization;
    //   },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      // this.$store.dispatch(`${MY_ORGANIZATION}${GET_SURVEYS}`, {
      //   organizationId: this.$route.params.organizationId,
      // });
    },
  },
};
</script>
