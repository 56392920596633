<template>
  <div>
    <section>
      <template>
        <v-container justify="center" align="center">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-card>
                <v-card-title
                  >Verfügbarkeitsprognose
                  <v-chip class="ml-2" small label
                    >{{ currentMonth }} {{ currentYear }}</v-chip
                  ></v-card-title
                >
                <v-card-subtitle
                  >Visualisierung basierend auf absoluten
                  Zahlen</v-card-subtitle
                >
                <v-card-text>
                  <v-sheet height="350">
                    <v-calendar
                      :value="today"
                      color="primary"
                      locale="de"
                      class="elevation-0"
                      @click:date="showDayData"
                    >
                      <template v-slot:day="{ date, past }">
                        <v-row class="fill-height">
                          <template v-if="tracked[date]">
                            <v-sheet
                              v-for="(percent, index) in tracked[date]"
                              :key="index"
                              :color="colors[index]"
                              :title="category[index]"
                              :width="`${percent}%`"
                              height="50%"
                              tile
                              :class="{ isPast: past }"
                            ></v-sheet>
                          </template>
                        </v-row>
                      </template>
                    </v-calendar>
                  </v-sheet>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-card>
                <v-card-title
                  >Verfügbarkeitsprognose
                  <v-chip class="ml-2" small label
                    >{{ currentMonth }} {{ currentYear }}</v-chip
                  ></v-card-title
                >
                <v-card-subtitle
                  >Visualisierung basierend auf absoluten
                  Zahlen</v-card-subtitle
                >
                <v-card-text>
                  <v-sheet height="350">
                    <v-calendar
                      :value="today"
                      color="primary"
                      locale="de"
                      class="elevation-0"
                      @click:date="showDayData"
                    >
                      <template v-slot:day="{ date, past }">
                        <v-row class="fill-height">
                          <template v-if="tracked[date]">
                            <v-sheet
                              v-for="(percent, index) in tracked[date]"
                              :key="index"
                              :color="colors[index]"
                              :title="category[index]"
                              :width="`${percent}%`"
                              height="50%"
                              tile
                              :class="{ isPast: past }"
                            ></v-sheet>
                          </template>
                        </v-row>
                      </template>
                    </v-calendar>
                  </v-sheet>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-card>
                <v-card-title
                  >Verfügbarkeitsprognose
                  <v-chip class="ml-2" small label
                    >{{ currentMonth }} {{ currentYear }}</v-chip
                  ></v-card-title
                >
                <v-card-subtitle
                  >Visualisierung basierend auf absoluten
                  Zahlen</v-card-subtitle
                >
                <v-card-text>
                  <v-sheet height="350">
                    <v-calendar
                      :value="today"
                      color="primary"
                      locale="de"
                      class="elevation-0"
                      @click:date="showDayData"
                    >
                      <template v-slot:day="{ date, past }">
                        <v-row class="fill-height">
                          <template v-if="tracked[date]">
                            <v-sheet
                              v-for="(percent, index) in tracked[date]"
                              :key="index"
                              :color="colors[index]"
                              :title="category[index]"
                              :width="`${percent}%`"
                              height="50%"
                              tile
                              :class="{ isPast: past }"
                            ></v-sheet>
                          </template>
                        </v-row>
                      </template>
                    </v-calendar>
                  </v-sheet>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <availability-status-overview-card></availability-status-overview-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
// import Subheader from "@/components/_systemwide/Subheader.vue";

//   import { MY_ORGANIZATION } from "@/store/modules.js";
//   import { GET_SURVEYS } from "@/store/action-types.js";
// import { SCHEDULING_EVENTS_RESPONSES_OWN_update } from "@/data/permission-types.js";
import AvailabilityStatusOverviewCard from "@/components/availability/AvailabilityStatusOverviewCard.vue";
export default {
  name: "availability-forecasts-tab-overview",
  components: {
    // Subheader,
    AvailabilityStatusOverviewCard,
  },
  data() {
    return {
      today: "2022-10-14",
      tracked1: [{ a: { color: "red", value: 100 } }],
      tracked: {
        "2022-10-01": [100],
        "2022-10-02": [100],
        "2022-10-03": [100],
        "2022-10-04": [100],
        "2022-10-05": [100],
        "2022-10-06": [100],
        "2022-10-07": [100],
        "2022-10-08": [100],
        "2022-10-09": [100],
        "2022-10-10": [100],
        "2022-10-11": [100],
        "2022-10-12": [100],
        "2022-10-13": [100],
        "2022-10-14": [100],
        "2022-10-15": [100],
        "2022-10-16": [100],
        "2022-10-17": [100],
        "2022-10-18": [100],
        "2022-10-19": [100],
        "2022-10-20": [100],
        "2022-10-21": [100],
        "2022-10-22": [100],
        "2022-10-23": [100],
        "2022-10-24": [100],
        "2022-10-25": [100],
        "2022-10-26": [100],
        "2022-10-27": [100],
        "2022-10-28": [100],
        "2022-10-29": [100],
        "2022-10-30": [100],
      },
      colors: ["#4CAF50FF", "#FF9800FF", "#F44336FF", "#F44336FF"],
      category: [
        "00:00 Uhr - 06:00 Uhr",
        "06:00 Uhr - 12:00 Uhr",
        "12:00 Uhr - 18:00 Uhr",
        "18:00 Uhr - 00:00 Uhr",
      ],
      category1: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    };
  },
  computed: {
    organization() {
      return this.$store.state.organization.activeOrganization;
    },
    surveys() {
      return this.$store.state.myOrganization.surveys;
    },
    events() {
      return this.$store.state.scheduling.events;
    },
    currentMonth() {
      return new Date().toLocaleString("de-DE", {
        month: "long",
      });
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      // this.$store.dispatch(`${MY_ORGANIZATION}${GET_SURVEYS}`, {
      //   organizationId: this.$route.params.organizationId,
      // });
    },
    showDayData(input) {
      console.log(input);
    },
    getColor(item) {
      console.log(item);
      return "#000";
    },
  },
};
</script>

<style>
.isPast {
  filter: opacity(0.4);
}
</style>
